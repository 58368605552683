const GreenSomething = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
		<rect x="0.736328" y="0.724609" width="8.32229" height="8.32229" fill="#BDF83F" />
		<rect x="0.736328" y="9.04688" width="8.32229" height="16.6446" fill="#BDF83F" />
		<rect x="9.05957" y="9.04688" width="8.32229" height="20.961" fill="#BDF83F" />
		<rect x="0.736328" y="25.6915" width="8.32229" height="8.32229" fill="#BDF83F" />
		<rect x="25.7051" y="0.724609" width="8.32229" height="8.32229" fill="#BDF83F" />
		<rect x="17.3818" y="17.3692" width="8.32229" height="8.32229" fill="#BDF83F" />
		<rect x="9.05957" y="25.6915" width="8.32229" height="8.32229" fill="#BDF83F" />
		<rect x="25.7031" y="25.6915" width="8.32229" height="8.32229" fill="#BDF83F" />
	</svg>
);

export default GreenSomething;
