import { ReactNode, createContext, useEffect, useState } from "react";
import { container } from "tsyringe";
import TokenResponseResource from "common/resources/Token/TokenResponseResource";
import TokenService from "Services/TokenService";

export type ITokenContext = {
	tokens: TokenResponseResource[];
};

type IProps = {
	children: ReactNode;
};

export const TokenContext = createContext<ITokenContext>(undefined!);

const tokenService = container.resolve(TokenService);

export function TokenProvider(props: IProps) {
	const [tokens, setTokens] = useState<TokenResponseResource[]>([]);

	useEffect(() => {
		tokenService.getTokens().then((res) => setTokens(res));
	}, []);

	return <TokenContext.Provider value={{ tokens }}>{props.children}</TokenContext.Provider>;
}
