import { useEffect, useState } from "react";

export enum EImageDirectory {
	TOKENS = "tokens",
	ASSOCIATIONS = "associations",
}

type IProps = {
	imageName: string;
	directory: EImageDirectory;
	className?: string;
};

/**
 * @param imageName // The name of the image, token symbol for example
 * @param directory // The directory where the image is located
 * @param className // The class name to be applied to the image
 * @returns // The image component
 */

export default function LazyImage(props: IProps) {
	const [imageSrc, setImageSrc] = useState(null);

	useEffect(() => {
		const loadImage = async () => {
			try {
				const image = await import(`Assets/Images/${props.directory}/${props.imageName}`);
				setImageSrc(image.default);
			} catch (error) {
				console.error("Error while fetching image:", error);
			}
		};

		loadImage();
	}, [props.imageName, props.directory]);

	return imageSrc ? <img src={imageSrc} alt={props.imageName} className={props.className} /> : null;
}
