import I18n from "Components/Materials/I18n";
import Layout from "../Layout";
import classes from "./classes.module.scss";
import TotalDonation from "Components/Materials/TotalDonation";
import HowItWorks from "./HowItWorks";
import CollectionCard from "Components/Materials/CollectionCard";
import UpcomingCollectionCard from "Components/Materials/UpcomingCollectionCard";
import { useToken } from "hooks/useTokens";

export default function Home() {
	const { tokens } = useToken();
	const totalDonation = tokens.reduce((acc, token) => acc + token.totalDonated, 0);

	return (
		<Layout tabTitle={I18n.asset.pages.home.tab_title} className={classes["root"]}>
			<TotalDonation total={totalDonation} />

			<div className={classes["collection-cards"]}>
				{tokens.map((token) => (
					<CollectionCard key={token.id} token={token} />
				))}
				<UpcomingCollectionCard />
				<UpcomingCollectionCard />
			</div>

			<HowItWorks />
		</Layout>
	);
}
