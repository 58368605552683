import React from "react";
import I18nStore, { ILngType } from "Stores/I18nStore";

type IVars = { [key: string]: string };

type IProps = {
	children?: React.ReactElement;
	text?: string;
	vars?: IVars;
	content?: (trads: string[]) => React.ReactNode;
};

type IState = {
	asset: ILngType;
};

/**
 * @example: usage <I18n map="menu_status.bla" vars={{myStringValue: this.state.myStringValue}}/>
 */
export default class I18n extends React.PureComponent<IProps, IState> {
	private removeOnChange?: () => void;

	public override render(): React.ReactNode {
		if (this.props.children) return React.cloneElement(this.props.children, { key: I18nStore.getInstance().lang });

		const trads = this.props.text ? [I18nStore.getInstance().trslt(this.props.text, this.props.vars)] : [];

		if (this.props.content) {
			return this.props.content(trads);
		}

		return trads.join(" ");
	}

	public override componentDidMount() {
		this.removeOnChange = I18nStore.getInstance().onChange((asset) => {
			this.setState({ asset });
			this.forceUpdate();
		});
	}

	public override componentWillUnmount() {
		this.removeOnChange && this.removeOnChange();
	}

	public static trslt(text: string, vars?: IVars) {
		return I18nStore.getInstance().trslt(text, vars);
	}

	public static get asset() {
		return I18nStore.getInstance().asset;
	}

	public static getLang() {
		return I18nStore.getInstance().lang;
	}

	public static getLocale() {
		return I18nStore.getInstance().locale;
	}
}
