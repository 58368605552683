import React, { PropsWithChildren } from "react";
import classes from "./classes.module.scss";
import classNames from "classnames";

export enum ETypo {
	H1 = "h1",
	H2 = "h2",
	H3 = "h3",
	H4 = "h4",
	H5 = "h5",
	SUBTITLE_32 = "subtitle_32",
	SUBTITLE_18 = "subtitle_18",
	NUMBERS = "numbers",
	BODY_18 = "body_18",
	BODY_16 = "body_16",
	BODY_14 = "body_14",
	CTA_LARGE = "cta_large",
	CTA_MEDIUM = "cta_medium",
	CTA_SMALL = "cta_small",
}

export enum ETypoWeight {
	REGULAR = "regular",
	MEDIUM = "medium",
	BOLD = "bold",
}

export enum ETypoComponent {
	H1 = "h1",
	H2 = "h2",
	H3 = "h3",
	H4 = "h4",
	H5 = "h5",
	H6 = "h6",
	P = "p",
	SPAN = "span",
	DIV = "div",
}

type IProps = PropsWithChildren<{
	typo: ETypo;
	weight?: ETypoWeight;
	className?: string | undefined;
	component?: ETypoComponent;
	onClick?: () => void;
}>;

export default function Typography({ typo, children, weight = ETypoWeight.MEDIUM, className, component = ETypoComponent.SPAN, onClick }: IProps) {
	return React.createElement(component, {
		className: classNames(classes["root"], classes[typo], classes[weight], className),
		children,
		onClick,
	});
}
