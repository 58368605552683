import { singleton } from "tsyringe";
import BaseApi from "./BaseApi";
import TokenResponseResource from "common/resources/Token/TokenResponseResource";

@singleton()
export default class TokenApi extends BaseApi {
	private baseurl = `${this.apiUrl}/tokens`;

	public async getTokens() {
		return this.getRequest<TokenResponseResource[]>(this.baseurl);
	}
}
