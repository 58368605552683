import { TokenProvider } from "Contexts/Token";
import I18n from "./Materials/I18n";
import Routing from "./Routing";

export default function App() {
	return (
		<I18n>
			<TokenProvider>
				<Routing />
			</TokenProvider>
		</I18n>
	);
}
