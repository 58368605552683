const CrossIcon = () => (
	<svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Group 1410103912">
			<rect id="Rectangle 8635" x="0.90332" width="9.99958" height="9.99958" fill="white" />
			<rect id="Rectangle 8637" x="10.9042" y="9.99957" width="9.99958" height="9.99958" fill="white" />
			<rect id="Rectangle 8642" x="0.90332" y="29.9987" width="9.99958" height="9.99958" fill="white" />
			<rect id="Rectangle 8651" x="30.9033" width="9.99958" height="9.99958" fill="white" />
			<rect id="Rectangle 8639" x="20.9042" y="19.9991" width="9.99958" height="9.99958" fill="white" />
			<rect id="Rectangle 8641" x="30.9024" y="29.9987" width="9.99958" height="9.99958" fill="white" />
		</g>
	</svg>
);

export default CrossIcon;
