import { TokenContext } from "Contexts/Token";
import { useContext } from "react";

export const useToken = () => {
	const context = useContext(TokenContext);
	if (context === undefined) {
		throw new Error("useToken must be used within a TokenProvider");
	}
	return context;
};
