import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ModuleStore from "../Stores/ModuleStore";
import ScrollToTop from "./Elements/ScrollToTop";
import ModulePage from "./Elements/ModulePage";
import Home from "./Pages/Home";
import Token from "./Pages/Token";

const { modules } = ModuleStore.get();

const router = createBrowserRouter(
	[
		{
			path: modules.pages.Home.props.path,
			element: (
				<ScrollToTop>
					<ModulePage from={modules.pages.Home} onDisabled={() => console.error("Home page disable")}>
						<Home />
					</ModulePage>
				</ScrollToTop>
			),
		},
		{
			path: modules.pages.Token.props.path,
			element: (
				<ScrollToTop>
					<ModulePage from={modules.pages.Token} onDisabled={() => console.error("Token page disable")}>
						<Token />
					</ModulePage>
				</ScrollToTop>
			),
		},
	],
	{
		future: {
			v7_fetcherPersist: true,
			v7_normalizeFormMethod: true,
			v7_relativeSplatPath: true,
			v7_partialHydration: true,
			v7_skipActionErrorRevalidation: true,
		},
	},
);

export default function Routing() {
	return <RouterProvider router={router} future={{ v7_startTransition: true }} />;
}
