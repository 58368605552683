const GreenCubeIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="34" height="35" viewBox="0 0 34 35" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0.22168 0.724609H33.5108V34.0138H0.22168V0.724609ZM6.10011 6.60304H27.6324V28.1353H6.10011V6.60304ZM20.6306 13.6058H13.1038V21.1326H20.6306V13.6058Z"
			fill="#BDF83F"
		/>
	</svg>
);

export default GreenCubeIcon;
