import Typography, { ETypo } from "Components/Elements/Typography";
import classes from "./classes.module.scss";
import I18n from "../I18n";

type IProps = {
	total: number;
};
export default function TotalDonation(props: IProps) {
	return (
		<div className={classes["root"]}>
			<Typography typo={ETypo.H3} className={classes["title"]}>
				<I18n text={I18n.asset.components.total_donations.title} />
			</Typography>

			<div className={classes["donation-container"]}>
				<Typography typo={ETypo.NUMBERS} className={classes["count"]}>
					${props.total.toLocaleString("en-US")}
				</Typography>
			</div>
		</div>
	);
}
