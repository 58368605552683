import KarmaLogo from "Assets/Images/logo.png";
import classNames from "classnames";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ModuleStore from "Stores/ModuleStore";
import classes from "./classes.module.scss";

type IProps = {
	className?: string;
};

export default function Logo(props: IProps) {
	const navigate = useNavigate();
	const navigateToHomepage = useCallback(() => navigate(ModuleStore.get().modules.pages.Home.props.path), [navigate]);

	return <img className={classNames(classes["root"], props.className)} src={KarmaLogo} alt="Karma Logo" onClick={navigateToHomepage} />;
}
