import developmentModule from "../Configs/ModuleConfig/development.json";
import stagingModule from "../Configs/ModuleConfig/staging.json";
import preprodModule from "../Configs/ModuleConfig/preprod.json";
import productionModule from "../Configs/ModuleConfig/production.json";

class ModuleConfig {
	private static instance: ModuleConfig;
	private config: typeof developmentModule = developmentModule;

	private constructor() {
		this.setConfig();
	}

	public static getInstance(): ModuleConfig {
		if (!ModuleConfig.instance) {
			ModuleConfig.instance = new ModuleConfig();
		}
		return ModuleConfig.instance;
	}

	public get() {
		return this.config;
	}

	private setConfig() {
		switch (process.env["REACT_APP_ENV"]) {
			case "staging":
				this.config = stagingModule;
				break;
			case "preprod":
				this.config = preprodModule;
				break;
			case "production":
				this.config = productionModule;
				break;
		}
	}
}

export default ModuleConfig.getInstance();
