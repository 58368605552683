const DonationRequestIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
		<rect x="0.578857" y="0.384094" width="4.93972" height="4.93972" fill="white" />
		<rect x="0.578613" y="5.32379" width="4.93972" height="4.93972" fill="white" />
		<rect x="5.51892" y="5.32379" width="4.93972" height="4.93972" fill="white" />
		<rect x="0.578613" y="15.2032" width="4.93972" height="4.93972" fill="white" />
		<rect x="15.3989" y="0.384094" width="4.93972" height="4.93972" fill="white" />
		<rect x="10.4586" y="10.2635" width="4.93972" height="4.93972" fill="white" />
		<rect x="10.4586" y="15.2032" width="4.93972" height="4.93972" fill="white" />
		<rect x="15.3978" y="15.2032" width="4.93972" height="4.93972" fill="white" />
	</svg>
);

export default DonationRequestIcon;
