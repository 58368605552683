import Typography, { ETypo } from "Components/Elements/Typography";
import classes from "./classes.module.scss";

type IProps = {
	title: string;
	description: string;
	icon: JSX.Element;
};

export default function HIWCard(props: IProps) {
	return (
		<div className={classes["root"]}>
			<div>{props.icon}</div>
			<Typography typo={ETypo.H3} className={classes["title"]}>
				{props.title}
			</Typography>
			<Typography typo={ETypo.BODY_14}>{props.description}</Typography>
		</div>
	);
}
