import { PropsWithChildren } from "react";
import classes from "./classes.module.scss";
import ThreeCubeIcon from "Assets/Icons/ThreeCubeIcon";
import CrossSmallIcon from "Assets/Icons/CrossSmallIcon";

type IProps = PropsWithChildren<{
	isOpen: boolean;
	onClose: () => void;
}>;

export default function Modal(props: IProps) {
	if (!props.isOpen) return null;
	return (
		<div className={classes["root"]}>
			<div className={classes["background"]} onClick={props.onClose} />
			<div className={classes["modal"]}>
				<div className={classes["icon"]}>
					<ThreeCubeIcon />
				</div>
				<div className={classes["content"]}>
					<div className={classes["header"]}>
						<CrossSmallIcon onClick={props.onClose} className={classes["cross-icon"]} />
					</div>
					{props.children}
				</div>
			</div>
		</div>
	);
}
