import { PropsWithChildren, useCallback, useState } from "react";
import classNames from "classnames";
import classes from "./classes.module.scss";
import Typography, { ETypo } from "../Typography";
import CopyIcon from "Assets/Images/copy-icon.png";

type IProps = PropsWithChildren<{
	value: string;
	className?: string;
}>;

export default function CopyString(props: IProps) {
	const [isCopied, setIsCopied] = useState(false);

	const copy = useCallback(() => {
		navigator.clipboard.writeText(props.value).then(() => {
			setIsCopied(true);
			setTimeout(() => setIsCopied(false), 1000);
		});
	}, [props.value]);

	return (
		<div className={classNames(classes["root"], props.className)}>
			<img src={CopyIcon} alt="copy" className={classes["icon"]} onClick={copy} />
			{!isCopied && props.children}
			{isCopied && (
				<div className={classes["alert"]}>
					<Typography typo={ETypo.SUBTITLE_18} className={classes["copied-text"]}>
						Copied!
					</Typography>
				</div>
			)}
		</div>
	);
}
