const CrossSmallSvg = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
		<rect x="0.632812" y="0.945557" width="4.93972" height="4.93972" fill="white" />
		<rect x="5.57227" y="5.88525" width="4.93972" height="4.93972" fill="white" />
		<rect x="0.632812" y="15.7646" width="4.93972" height="4.93972" fill="white" />
		<rect x="15.4531" y="0.945557" width="4.93972" height="4.93972" fill="white" />
		<rect x="10.5117" y="10.825" width="4.93972" height="4.93972" fill="white" />
		<rect x="15.4512" y="15.7646" width="4.93972" height="4.93972" fill="white" />
	</svg>
);

export default function CrossSmallIcon(props: { onClick?: () => void; className?: string }) {
	return (
		<div onClick={props.onClick} className={props.className}>
			<CrossSmallSvg />
		</div>
	);
}
