const LeftArrowSvg = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="39" height="26" viewBox="0 0 39 26" fill="none">
		<path d="M7.69245 9.04747H15.3849V16.7399H7.69245V9.04747Z" fill="white" />
		<path d="M0 9.04747H7.69245V16.7399H0V9.04747Z" fill="white" />
		<path d="M23.0774 9.04747H30.7698V16.7399H23.0774V9.04747Z" fill="white" />
		<path d="M30.7698 9.04747H38.4623V16.7399H30.7698V9.04747Z" fill="white" />
		<path d="M15.3849 9.04747H23.0774V16.7399H15.3849V9.04747Z" fill="white" />
		<path d="M5.17032 5.27953H12.8628V10.3355H5.17032V5.27953Z" fill="white" />
		<path d="M12.8628 0.223633H20.5552V5.27955L12.8628 5.27953V0.223633Z" fill="white" />
		<path d="M5.17032 15.3913H12.8628V20.4473H5.17032V15.3913Z" fill="white" />
		<path d="M12.8628 5.27953L20.5552 5.27955V25.3872H12.8628V5.27953Z" fill="white" />
	</svg>
);

export default function LeftArrowIcon(props: { className?: string; onClick?: () => void }) {
	return (
		<div className={props.className} onClick={props.onClick}>
			<LeftArrowSvg />
		</div>
	);
}
