const GreenStairsIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
		<rect x="0.898438" y="9.04688" width="8.32229" height="16.6446" fill="#BDF83F" />
		<rect x="9.22168" y="0.724609" width="8.32229" height="33.2892" fill="#BDF83F" />
		<rect x="0.898438" y="25.6915" width="8.32229" height="8.32229" fill="#BDF83F" />
		<rect x="17.5439" y="17.3692" width="8.32229" height="16.6446" fill="#BDF83F" />
		<rect x="25.8652" y="25.6915" width="8.32229" height="8.32229" fill="#BDF83F" />
	</svg>
);

export default GreenStairsIcon;
