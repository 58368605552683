import developmentConfig from "../Configs/ApplicationConfig/development.json";
import stagingConfig from "../Configs/ApplicationConfig/staging.json";
import preprodConfig from "../Configs/ApplicationConfig/preprod.json";
import productionConfig from "../Configs/ApplicationConfig/production.json";

class ApplicationConfig {
	private static instance: ApplicationConfig;
	private config: typeof developmentConfig = developmentConfig;

	private constructor() {
		this.setConfig();
	}

	public static getInstance(): ApplicationConfig {
		if (!ApplicationConfig.instance) {
			ApplicationConfig.instance = new ApplicationConfig();
		}
		return ApplicationConfig.instance;
	}

	public get() {
		return this.config;
	}

	private setConfig() {
		switch (process.env["REACT_APP_ENV"]) {
			case "staging":
				this.config = stagingConfig;
				break;
			case "preprod":
				this.config = preprodConfig;
				break;
			case "production":
				this.config = productionConfig;
				break;
		}
	}
}

export default ApplicationConfig.getInstance();
