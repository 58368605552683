import { PropsWithChildren } from "react";
import useHead from "hooks/useHead";
import Header from "Components/Materials/Header";
import classes from "./classes.module.scss";
import Footer from "Components/Materials/Footer";

type IProps = PropsWithChildren<{
	tabTitle: string;
	className?: string;
}>;

export default function Layout(props: IProps) {
	useHead({ title: props.tabTitle });
	return (
		<div className={classes["root"]}>
			<Header />
			<main className={props.className}>{props.children}</main>
			<Footer />
		</div>
	);
}
