import I18n from "Components/Materials/I18n";
import Layout from "../Layout";
import classes from "./classes.module.scss";
import LeftArrowIcon from "Assets/Icons/LeftArrowIcon";
import Typography, { ETypo, ETypoWeight } from "Components/Elements/Typography";
import { useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ModuleStore from "Stores/ModuleStore";
import classNames from "classnames";
import Button, { EButtonIconPosition } from "Components/Elements/Button";
import WebsiteIcon from "Assets/Icons/WebsiteIcon";
import TelegramIcon from "Assets/Images/socials/telegram-icon.png";
import DiscordIcon from "Assets/Images/socials/discord-icon.png";
import TwitterIcon from "Assets/Images/socials/twitter-icon.png";
import CopyString from "Components/Elements/CopyString";
import { useToken } from "hooks/useTokens";
import LazyImage, { EImageDirectory } from "Components/Materials/LazyImage";

const { modules } = ModuleStore.get();

export default function Token() {
	const { symbol } = useParams();
	const { tokens } = useToken();
	const navigate = useNavigate();
	const token = tokens.find((token) => token.symbol === symbol);

	const backToHome = useCallback(() => navigate(modules.pages.Home.props.path), [navigate]);

	if (!token) {
		return null;
	}

	const karmaLevelPercentage = (token.karmaLevel / 16) * 100;
	return (
		<Layout tabTitle={I18n.asset.pages.token.tab_title} className={classes["root"]}>
			<div className={classes["header"]}>
				<div className={classes["ticker-container"]}>
					<LeftArrowIcon className={classes["arrow-icon"]} onClick={backToHome} />
					<Typography typo={ETypo.H2} className={classes["ticker"]}>
						${token.symbol.toUpperCase()}
					</Typography>
				</div>

				<div className={classes["milestones-container"]}>
					<Typography typo={ETypo.H3}>
						{I18n.asset.pages.token.karma_level} ({token.karmaLevel}/16)
					</Typography>
					<div className={classes["progress-bar"]}>
						<Typography typo={ETypo.NUMBERS}>{karmaLevelPercentage}%</Typography>
						<div className={classes["percentage"]} style={{ width: `${karmaLevelPercentage}%` }} />
					</div>
					<Typography typo={ETypo.SUBTITLE_18} className={classes["next-donation-text"]}>
						{I18n.asset.pages.token.next_donation} ${token.nextDonation.toLocaleString("en-US")}
					</Typography>
				</div>
			</div>

			<div className={classes["token-asso-container"]}>
				<div className={classes["gecko-token-container"]}>
					<div className={classes["gecko-terminal-container"]}>
						<iframe
							height="100%"
							width="100%"
							id="geckoterminal-embed"
							title="GeckoTerminal Embed"
							src={`https://www.geckoterminal.com/fr/solana/pools/${token.contractAddress}?embed=1&info=1&swaps=1&grayscale=0&light_chart=0`}
							allow="clipboard-write"
							allowFullScreen></iframe>
					</div>

					<div className={classes["token-container"]}>
						<div className={classNames(classes["container"], classes["live-donations"])}>
							<Typography typo={ETypo.H3}>Live donations</Typography>
							<Typography typo={ETypo.NUMBERS} className={classes["donation-count"]}>
								${token.totalDonated.toLocaleString("en-US")}
							</Typography>
						</div>

						<div className={classes["token-info-social-container"]}>
							<div className={classNames(classes["container"], classes["token-info"])}>
								<div className={classes["token-info-header"]}>
									<LazyImage imageName={`${token.symbol}.png`} directory={EImageDirectory.TOKENS} className={classes["image"]} />

									<Typography typo={ETypo.H3} className={classes["token-name"]}>
										{token.name.toUpperCase()}
									</Typography>
								</div>

								<div className={classes["separator"]} />

								<div className={classes["info-button-container"]}>
									<div className={classes["info-container"]}>
										<div className={classes["item"]}>
											<Typography typo={ETypo.BODY_16} weight={ETypoWeight.BOLD}>
												{I18n.asset.pages.token.market_cap}
											</Typography>
											<Typography typo={ETypo.BODY_16} weight={ETypoWeight.BOLD} className={classes["item-value"]}>
												${token.marketCap.toLocaleString("en-US")}
											</Typography>
										</div>
										<div className={classes["item"]}>
											<Typography typo={ETypo.BODY_16} weight={ETypoWeight.BOLD}>
												{I18n.asset.pages.token.karma_level}
											</Typography>
											<Typography typo={ETypo.BODY_16} weight={ETypoWeight.BOLD} className={classes["item-value"]}>
												{token.karmaLevel}/16
											</Typography>
										</div>
										<div className={classes["item"]}>
											<Typography typo={ETypo.BODY_16} weight={ETypoWeight.BOLD}>
												{I18n.asset.pages.token.token_contract}
											</Typography>
											<CopyString value={"8X4Y...ZrX4"} className={classes["copy"]}>
												<Typography typo={ETypo.BODY_16} weight={ETypoWeight.BOLD} className={classes["item-value"]}>
													{`${token.contractAddress.slice(0, 4)}...${token.contractAddress.slice(
														token.contractAddress.length - 4,
														token.contractAddress.length,
													)}`}
												</Typography>
											</CopyString>
										</div>
									</div>

									<Button fullwidth>
										{I18n.asset.pages.token.trade} ${token.symbol.toUpperCase()}
									</Button>
								</div>
							</div>

							<div className={classNames(classes["container"], classes["socials-container"])}>
								<Typography typo={ETypo.H3}>Socials</Typography>
								<div className={classes["socials"]}>
									{token.socialMedia?.telegram && (
										<Link to={token.socialMedia.telegram} target="_blank" rel="noreferrer">
											<img src={TelegramIcon} alt="Telegram" className={classes["social-icon"]} />
										</Link>
									)}
									{token.socialMedia?.twitter && (
										<Link to={token.socialMedia.twitter} target="_blank" rel="noreferrer">
											<img src={TwitterIcon} alt="Twitter" className={classes["social-icon"]} />
										</Link>
									)}
									{token.socialMedia?.discord && (
										<Link to={token.socialMedia.discord} target="_blank" rel="noreferrer">
											<img src={DiscordIcon} alt="Discord" className={classes["social-icon"]} />
										</Link>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className={classes["asso-container"]}>
					<div className={classNames(classes["container"], classes["asso-info"])}>
						<LazyImage imageName={`${token.symbol}.png`} directory={EImageDirectory.ASSOCIATIONS} className={classes["asso-info-image"]} />

						<div className={classes["button-container"]}>
							<Typography typo={ETypo.BODY_18} className={classes["asso-info-text"]}>
								{token.association.name}
							</Typography>
							<Link to={token.association.website} target="_blank" rel="noreferrer">
								<Button icon={<WebsiteIcon />} iconposition={EButtonIconPosition.LEFT}>
									{I18n.asset.pages.token.visit_website}
								</Button>
							</Link>
						</div>
					</div>

					<div className={classNames(classes["container"], classes["text-container"])}>
						<Typography typo={ETypo.H3} className={classes["text"]}>
							{I18n.asset.pages.token.association_of_donation}
						</Typography>
						<Typography typo={ETypo.BODY_14} weight={ETypoWeight.REGULAR}>
							{token.association.description}
						</Typography>
					</div>
				</div>
			</div>
		</Layout>
	);
}
