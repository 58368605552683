import Typography, { ETypo } from "Components/Elements/Typography";
import classes from "./classes.module.scss";

export default function UpcomingCollectionCard() {
	return (
		<div className={classes["root"]}>
			<div className={classes["spacer"]} />
			<Typography typo={ETypo.SUBTITLE_18} className={classes["text"]}>
				UPCOMING
			</Typography>
		</div>
	);
}
