import Typography, { ETypo } from "Components/Elements/Typography";
import classes from "./classes.module.scss";
import I18n from "Components/Materials/I18n";
import HIWCard from "./HIWCard";
import GreenCubeIcon from "Assets/Icons/GreenCubeIcon";
import GreenBirdIcon from "Assets/Icons/GreenBirdIcon";
import GreenStairsIcon from "Assets/Icons/GreenStairsIcon";
import GreenSomething from "Assets/Icons/GreenSomething";

export default function HowItWorks() {
	return (
		<div className={classes["root"]}>
			<Typography typo={ETypo.H2}>
				<I18n text={I18n.asset.pages.home.how_it_works.title} />
			</Typography>

			<div className={classes["content"]}>
				<HIWCard icon={<GreenCubeIcon />} title={I18n.asset.pages.home.how_it_works.choose.title} description={I18n.asset.pages.home.how_it_works.choose.description} />
				<HIWCard icon={<GreenBirdIcon />} title={I18n.asset.pages.home.how_it_works.meme.title} description={I18n.asset.pages.home.how_it_works.meme.description} />
				<HIWCard icon={<GreenStairsIcon />} title={I18n.asset.pages.home.how_it_works.choose.title} description={I18n.asset.pages.home.how_it_works.choose.description} />
				<HIWCard icon={<GreenSomething />} title={I18n.asset.pages.home.how_it_works.meme.title} description={I18n.asset.pages.home.how_it_works.meme.description} />
			</div>
		</div>
	);
}
