import Button from "Components/Elements/Button";
import classes from "./classes.module.scss";
import Typography, { ETypo, ETypoWeight } from "Components/Elements/Typography";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import ModuleStore from "Stores/ModuleStore";
import TokenResponseResource from "common/resources/Token/TokenResponseResource";
import I18n from "../I18n";
import LazyImage, { EImageDirectory } from "../LazyImage";

type IProps = {
	token: TokenResponseResource;
};

const { modules } = ModuleStore.get();

export default function CollectionCard(props: IProps) {
	const navigate = useNavigate();
	const navigateToTokenPage = useCallback(() => navigate(modules.pages.Token.props.path.replace(":symbol", props.token.symbol)), [navigate, props.token.symbol]);
	const karmaLevelPercentage = (props.token.karmaLevel / 16) * 100;

	return (
		<div className={classes["root"]} onClick={navigateToTokenPage}>
			<div className={classes["image"]}>
				<LazyImage imageName={`${props.token.symbol}.png`} directory={EImageDirectory.TOKENS} />
			</div>

			<div className={classes["content"]}>
				<div className={classes["header"]}>
					<div className={classes["title-container"]}>
						<Typography typo={ETypo.H4}>{props.token.name}</Typography>
						<Typography typo={ETypo.H5} className={classes["ticker"]}>
							${props.token.symbol}
						</Typography>
					</div>
					<Typography typo={ETypo.BODY_14}>{props.token.description}</Typography>
				</div>

				<div className={classes["down-part"]}>
					<div className={classes["stats-container"]}>
						<div className={classes["stats-line"]}>
							<Typography typo={ETypo.BODY_14} weight={ETypoWeight.BOLD}>
								{I18n.asset.components.collection_card.market_cap}
							</Typography>
							<Typography typo={ETypo.BODY_14} weight={ETypoWeight.BOLD} className={classes["marketcap-number"]}>
								${props.token.marketCap.toLocaleString("en-US")}
							</Typography>
						</div>

						<div className={classes["stats-line"]}>
							<Typography typo={ETypo.BODY_14} weight={ETypoWeight.BOLD}>
								{I18n.asset.components.collection_card.karma_level}
							</Typography>
							<Typography typo={ETypo.BODY_14} weight={ETypoWeight.BOLD} className={classes["marketcap-number"]}>
								Level {props.token.karmaLevel}/16
							</Typography>
						</div>
					</div>

					<div className={classes["button-container"]}>
						<div className={classes["progress-bar"]}>
							<Typography typo={ETypo.BODY_14} weight={ETypoWeight.BOLD} className={classes["percentage-text"]}>
								{karmaLevelPercentage}%
							</Typography>
							<div className={classes["percentage"]} style={{ width: `${karmaLevelPercentage}%` }} />
						</div>

						<Button className={classes["button"]} fullwidth onClick={navigateToTokenPage}>
							{I18n.asset.components.collection_card.make_it_pump}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}
