import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import classes from "./classes.module.scss";
import Typography, { ETypo } from "../Typography";

export enum EButtonType {
	BUTTON = "button",
	SUBMIT = "submit",
}

export enum EButtonIconPosition {
	LEFT = "left",
	RIGHT = "right",
}

type IProps = PropsWithChildren<{
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
	fullwidth?: boolean;
	icon?: JSX.Element;
	disabled?: boolean;
	type?: EButtonType;
	iconposition?: EButtonIconPosition;
	className?: string;
}>;

export default function Button(props: IProps) {
	let { disabled = false, type = EButtonType.BUTTON, fullwidth = false, iconposition = EButtonIconPosition.RIGHT, onClick, children, icon, className } = props;

	const fullwidthattr = fullwidth.toString();

	const attributes = { ...props, disabled, type, fullwidthattr };
	delete attributes.fullwidth;
	delete attributes.icon;
	delete attributes.iconposition;

	return (
		<button {...attributes} onClick={onClick} className={classNames(classes["root"], className)} type={type}>
			{icon && iconposition === EButtonIconPosition.LEFT && icon}
			<Typography typo={ETypo.H5} className={classes["text"]}>
				{children}
			</Typography>
			{icon && iconposition === EButtonIconPosition.RIGHT && icon}
		</button>
	);
}
