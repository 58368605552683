const BurgerMenuIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 30 23" fill="none">
		<rect x="29.9033" y="0.441376" width="4.93972" height="29.041" transform="rotate(90 29.9033 0.441376)" fill="white" />
		<rect x="19.416" y="10.321" width="4.93972" height="18.5534" transform="rotate(90 19.416 10.321)" fill="white" />
		<rect x="29.9033" y="20.1997" width="4.93972" height="29.041" transform="rotate(90 29.9033 20.1997)" fill="white" />
		<rect x="29.9033" y="10.3203" width="4.9397" height="4.93987" transform="rotate(90 29.9033 10.3203)" fill="white" />
		<rect x="8.12231" y="10.3203" width="4.93972" height="7.26025" transform="rotate(90 8.12231 10.3203)" fill="white" />
	</svg>
);

export default BurgerMenuIcon;
