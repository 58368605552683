const GreenBirdIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="34" height="35" viewBox="0 0 34 35" fill="none">
		<rect x="0.0595703" y="0.724609" width="8.32229" height="8.32229" fill="#BDF83F" />
		<rect x="0.0595703" y="9.04688" width="8.32229" height="8.32229" fill="#BDF83F" />
		<rect x="8.38281" y="9.04688" width="8.32229" height="8.32229" fill="#BDF83F" />
		<rect x="0.0595703" y="25.6915" width="8.32229" height="8.32229" fill="#BDF83F" />
		<rect x="25.0283" y="0.724609" width="8.32229" height="8.32229" fill="#BDF83F" />
		<rect x="16.7051" y="17.3692" width="8.32229" height="8.32229" fill="#BDF83F" />
		<rect x="16.7051" y="25.6915" width="8.32229" height="8.32229" fill="#BDF83F" />
		<rect x="25.0264" y="25.6915" width="8.32229" height="8.32229" fill="#BDF83F" />
	</svg>
);

export default GreenBirdIcon;
