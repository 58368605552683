const trads = {
	common: {
		welcome: "Welcome to the React Boilerplate",
	},
	pages: {
		token: {
			tab_title: "Token Page",
			visit_website: "website",
			association_of_donation: "Association of donation",
			donation_contract: "Donation contract",
			token_contract: "Token contract",
			karma_level: "Karma Level",
			market_cap: "Market Cap",
			next_donation: "Next donation:",
			trade: "TRADE",
		},
		home: {
			tab_title: "Home",
			title: "Trade for Karma",
			live: "Live",
			upcoming: "Upcoming",
			how_it_works: {
				title: "How it works",
				choose: {
					title: "Choose your cause",
					description:
						"Every token is linked to a specific cause. During our initial launch, we feature two tokens: $Bull and $Bear, each supporting wildlife protection.",
				},
				meme: {
					title: "Choose your meme",
					description:
						"Every token is linked to a specific cause. During our initial launch, we feature two tokens: $Bull and $Bear, each supporting wildlife protection.",
				},
			},
		},
	},
	components: {
		collection_card: {
			market_cap: "MARKETCAP",
			make_it_pump: "Make it pump",
			karma_level: "KARMA LEVEL",
		},
		header: {
			connect: "Connect",
			items: {
				about_us: "About Us",
				donation_request: "Donation Request",
				twitter_link: "karma.fun",
			},
		},
		footer: {
			text: "With every pump, we aim to make the world",
			colored_text: "a little bit better",
		},
		total_donations: {
			title: "total donations",
		},
		about_us_modal: {
			welcome: "Welcome to",
			signature: "With every pump, we aim to make the world",
			signature_color: "a little better.",
			whitepaper_button: "Whitepaper",
			description_1:
				"Karma.fun is a groundbreaking protocol that leverages the power of memecoins to drive meaningful global change by funding impactful donations. By bridging the fun of memecoins with philanthropy, Karma.fun creates a sustainable loop of humor, community, and positive action.",
			description_2:
				"Each token is tied to a specific cause, such as environment, education, or animals, ensuring a direct link between your trading activity and the causes you care about most.",
		},
	},
};

export default trads;
