import Typography, { ETypo } from "Components/Elements/Typography";
import Logo from "../Logo";
import classes from "./classes.module.scss";
import I18n from "../I18n";

export default function Footer() {
	return (
		<footer className={classes["root"]}>
			<Logo className={classes["logo"]} />
			<div className={classes["text-container"]}>
				<Typography typo={ETypo.BODY_14}>
					<I18n text={I18n.asset.components.footer.text} />
				</Typography>
				<Typography typo={ETypo.BODY_14} className={classes["colored-text"]}>
					<I18n text={I18n.asset.components.footer.colored_text} />
				</Typography>
			</div>
		</footer>
	);
}
