import "reflect-metadata";
import "./index.css";
import "Themes";
import App from "./Components/App";
import "reflect-metadata";

import ReactDOM from "react-dom/client";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);
