import TokenApi from "Api/TokenApi";
import TokenServiceClassToken from "common/resources/injectables/TokenServiceClassToken";
import { container, singleton } from "tsyringe";

@singleton()
export default class TokenService implements TokenServiceClassToken {
	private api: TokenApi = container.resolve(TokenApi);

	public async getTokens() {
		return this.api.getTokens();
	}
}
