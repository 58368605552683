import AboutUsIcon from "Assets/Icons/AboutUsIcon";
import DonationRequestIcon from "Assets/Icons/DonationRequestIcon";
import Typography, { ETypo } from "Components/Elements/Typography";
import I18n from "Components/Materials/I18n";
import Logo from "Components/Materials/Logo";
import classes from "./classes.module.scss";
import TwitterLogo from "Assets/Images/twitter-logo.png";
import CrossIcon from "Assets/Icons/CrossIcon";
import classNames from "classnames";

type IProps = {
	isOpen: boolean;
	onClose: () => void;
	onAboutUsClick: () => void;
	onDonationRequestClick: () => void;
	onTwitterLinkClick: () => void;
};

export default function MobileMenu(props: IProps) {
	return (
		<div
			className={classNames(classes["root"], {
				[classes["open"]!]: props.isOpen,
			})}>
			<div className={classes["header"]}>
				<Logo className={classes["logo"]} />
				<div className={classes["cross-icon"]} onClick={props.onClose}>
					<CrossIcon />
				</div>
			</div>

			<div className={classes["navbar"]}>
				<div className={classes["item"]} onClick={props.onTwitterLinkClick}>
					<img src={TwitterLogo} alt="twitter logo" className={classes["twitter-logo"]} />
					<Typography typo={ETypo.H4}>
						<I18n text={I18n.asset.components.header.items.twitter_link} />
					</Typography>
				</div>

				<div className={classes["item"]} onClick={props.onDonationRequestClick}>
					<DonationRequestIcon />
					<Typography typo={ETypo.H4}>
						<I18n text={I18n.asset.components.header.items.donation_request} />
					</Typography>
				</div>

				<div className={classes["item"]} onClick={props.onAboutUsClick}>
					<AboutUsIcon />
					<Typography typo={ETypo.H4}>
						<I18n text={I18n.asset.components.header.items.about_us} />
					</Typography>
				</div>
			</div>
		</div>
	);
}
