const WebsiteIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
		<path d="M10 1.30664H9V2.30664H10V1.30664Z" fill="white" />
		<path d="M9 2.30664V3.30664H8V5.30664H7V8.30664H2V7.30664H3V5.30664H4V4.30664H5V3.30664H7V2.30664H9Z" fill="white" />
		<path d="M13 2.30664H14V4.30664H15V6.30664H16V8.30664H8V6.30664H9V4.30664H10V2.30664H11V1.30664H13V2.30664Z" fill="white" />
		<path d="M15 1.30664H14V2.30664H15V1.30664Z" fill="white" />
		<path d="M22 7.30664V8.30664H17V5.30664H16V3.30664H15V2.30664H17V3.30664H19V4.30664H20V5.30664H21V7.30664H22Z" fill="white" />
		<path d="M17 10.3066V14.3066H16V15.3066H8V14.3066H7V10.3066H8V9.30664H16V10.3066H17Z" fill="white" />
		<path d="M1 9.30664H7V10.3066H6V14.3066H7V15.3066H1V9.30664Z" fill="white" />
		<path d="M23 9.30664V15.3066H17V14.3066H18V10.3066H17V9.30664H23Z" fill="white" />
		<path d="M22 16.3066V17.3066H21V19.3066H20V20.3066H19V21.3066H17V22.3066H15V21.3066H16V19.3066H17V16.3066H22Z" fill="white" />
		<path d="M10 22.3066H9V23.3066H10V22.3066Z" fill="white" />
		<path d="M9 21.3066V22.3066H7V21.3066H5V20.3066H4V19.3066H3V17.3066H2V16.3066H7V19.3066H8V21.3066H9Z" fill="white" />
		<path d="M15 22.3066H14V23.3066H15V22.3066Z" fill="white" />
		<path d="M14 22.3066H13V23.3066H11V22.3066H10V20.3066H9V18.3066H8V16.3066H16V18.3066H15V20.3066H14V22.3066Z" fill="white" />
	</svg>
);

export default WebsiteIcon;
