const AboutUsIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
		<rect x="0.543213" y="5.32373" width="4.93972" height="9.87944" fill="white" />
		<rect x="5.48352" y="0.384033" width="4.93972" height="19.7589" fill="white" />
		<rect x="0.543213" y="15.2032" width="4.93972" height="4.93972" fill="white" />
		<rect x="10.4232" y="10.2634" width="4.93972" height="9.87944" fill="white" />
		<rect x="15.3623" y="15.2032" width="4.93972" height="4.93972" fill="white" />
	</svg>
);

export default AboutUsIcon;
